export default {
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "naanMudhalvan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAAN MUDHALVAN"])},
  "loginTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "forgotUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Username?"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "captchaImageHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the characters on image"])},
  "studentRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Registration"])},
  "registrationHelp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read the following instructions before filling this form"])},
  "registrationHelpLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid email address and contact number to register with Naan Mudhalvan."])},
  "registrationHelpLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All official information / communication will ONLY be sent to the Email Address and contact number provided during the registration."])},
  "registrationHelpLine3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denotes that it is mandatory to fill."])},
  "registrationHelpLine4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure all the relevant fields are accurately filled."])},
  "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Details"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
  "emailId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ID"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "selectCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Category"])},
  "communitySelectionHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid caste certificate to get 50% fee concession"])},
  "uploadCasteCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Caste Certificate"])},
  "uploadValidPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload valid PDF / Image file"])},
  "academicDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Details"])},
  "collegeRollNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Roll No"])},
  "branchSpecialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch / Specialization"])},
  "selectedBranchSpecialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Branch / Specialization"])},
  "yearOfJoining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of Joining"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "permanentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent Address"])},
  "enterAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Address"])},
  "villageCityTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Village / City / Town"])},
  "pinCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Code"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select State"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
  "enterDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter District"])},
  "currentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Address"])},
  "sameAsPermanentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as permanent address"])},
  "loginDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Details"])},
  "createUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your username"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "colleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colleges"])},
  "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College"])},
  "invitedColleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited Colleges"])},
  "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "courseEnrolments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Enrolments"])},
  "validateCasteCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate Caste Certificate"])},
  "validatePassoutStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate Passout Students"])},
  "addStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Student"])},
  "enrolledCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrolled Courses"])},
  "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
  "psychometricTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychometric Test"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "collegeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Details"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
  "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "courseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Name"])},
  "totalVideos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Videos"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "createdOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created On"])},
  "updatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated On"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "allDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Districts"])},
  "typeOfInstitution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Institution"])},
  "resendInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Invite"])},
  "editResendInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit & Resend Invite"])},
  "totalInviteColleges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Invite Colleges"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered"])},
  "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited"])},
  "collegeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Name"])},
  "institutionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution Type"])},
  "invitedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited On"])},
  "searchStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Students"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "uploadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload CSV"])},
  "totalResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Results"])},
  "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
  "studentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Details"])},
  "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
  "uploadAFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
  "orDragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop"])},
  "CSVFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV File"])},
  "fileUploadedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File uploaded successfully"])},
  "checkCSVDetailsBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the uploaded CSV file details in below"])},
  "wantToSendInvitePopMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to send invitation to selected college(s)"])},
  "sendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Invitation"])},
  "emailInvitationsInitiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email invitations initiated"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])}
}