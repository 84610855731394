<template>
  <!-- Add NavBar -->
  <div
      :class="{
    'loading-blur': isLoading,
      }"
      class="">
    <router-view/>
    <l-loading></l-loading>
  </div>
</template>
<script>
import LLoading from "@/components/custom/loading/LLoading";
import {mapGetters} from "vuex";
export default {
  components: { LLoading },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  created() {
    this.$store.dispatch("checkLoginStatus");
    this.$store.dispatch("getRegistrationDropDowns");
    this.$store.dispatch("getAffiliatedUniversities");
    this.$store.dispatch("getDistrictsData");
  },
};
</script>
<style lang="scss">
</style>
