import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store/index";

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    name: "App",
    component: () =>
        import(/* webpackChunkName: "base" */ "../layouts/NavBarLayout.vue"),
    children: [
      {
        path: "/",
        name: 'Home',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "base" */ '../views/CheckingPage.vue'),
      },
      // Admin Endpoints Start
      // {
      //   path: '/admin/colleges',
      //   redirect: '/admin/colleges/invite',
      //   meta: { requiresAuth: true },
      // },
      // {
      //   path: '/admin/colleges',
      //   redirect: '/admin/colleges/invite',
      //   meta: { requiresAuth: true },
      // },
      // {
      //   path: '/admin/colleges/invite',
      //   name: 'AdminColleges',
      //   meta: { requiresAuth: true },
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminColleges.vue')
      // },
      {
        path: '/admin/colleges/upload',
        name: 'AdminCollegesCSVUpload',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/InviteCollegesUploadPage.vue')
      },
      {
        path: '/admin/colleges/invited',
        name: 'AdminInvitedColleges',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminInvitedColleges.vue')
      },
      {
        path: '/admin/colleges/',
        name: 'AdminColleges',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminRegisteredColleges.vue')
      },
      {
        path: '/admin/college/:collegeId',
        name: 'AdminCollegeDetails',
        props: true,
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminCollegeDetails.vue')
      },
      {
        path: '/admin/students',
        name: 'AdminStudents',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminRegisteredStudents.vue')
      },
      {
        path: '/admin/student/:studentId',
        name: 'AdminStudentDetailsPage',
        props: true,
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminStudentDetailsPage.vue')
      },
      {
        path: '/admin/courses/',
        name: 'AdminCourses',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminCoursesPage.vue')
      },
      // Admin Endpoints End
      //  College Endpoints Start

      {
        path: '/college/profile',
        name: 'CollegeProfilePage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/profile/CollegeProfilePage.vue')
      },
      {
        path: '/college/students/add',
        name: 'CollegeStudentsAdd',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/invite/AddStudentFormPage.vue')
      },
      {
        path: '/college/students/list',
        name: 'CollegeStudentsList',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/StudentsListPage.vue')
      },
      {
        path: '/college/student/:studentId',
        name: 'CollegeStudentDetailsPage',
        props: true,
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminStudentDetailsPage.vue')
      },
      {
        path: '/college/students/validate',
        name: 'StudentValidateCasteCertificate',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/validate/StudentValidateCasteCertificate.vue')
      },
      {
        path: '/college/students/passout-validate',
        name: 'StudentValidateProvisionalCertificate',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/validate/StudentValidateProvisionalCertificate.vue')
      },
      {
        path: '/college/students/upload',
        name: 'InviteStudentsUploadPage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/invite/InviteStudentsUploadPage.vue')
      },
      {
        path: '/college/enrolled/courses',
        name: 'StudentEnrolledCourseListPage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/enrolled/StudentEnrolledCourseListPage.vue')
      },
    //    College Endpoints End
    //    Student Endpoints Start
      {
        path: '/students/home',
        name: 'StudentsHomePage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/StudentsHomePage.vue')
      },
      {
        path: '/students/psychometric',
        name: 'StudentPsychometricHome',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/psychometric/StudentPsychometricHome.vue')
      },
      {
        path: '/students/psychometric/test/:testType',
        name: 'StudentPsychometric',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/psychometric/PsychometricPage.vue')
      },
      {
        path: '/jobs',
        name: 'StudentJobsHomePage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/jobs/StudentJobsHomePage.vue')
      },
      {
        path: '/jobs/:sectorId',
        name: 'StudentJobSectorCompaniesPage',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/jobs/StudentJobSectorCompaniesPage.vue')
      },
      {
        path: '/jobs/:sectorId/:companyId',
        name: 'StudentCompanyJobsPage',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/jobs/StudentCompanyJobsPage.vue')
      },
      {
        path: '/course/partner/:courseId',
        name: 'CoursePartnerPage',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/courses/CoursePartnerPage.vue')
      },
      {
        path: '/courses',
        name: 'StudentCourseListPage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/courses/StudentCourseListPage.vue')
      },
      {
        path: '/courses/my',
        name: 'StudentMyCourseListPage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/courses/StudentMyCourseListPage.vue')
      },
      {
        path: '/course/:courseId',
        name: 'StudentCourseDetailPage',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/courses/StudentCourseDetailPage.vue')
      },
      {
        path: '/course/certificate/:courseId',
        name: 'StudentCourseCertificate',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/courses/StudentCourseCertificate.vue')
      },
      {
        path: '/s/profile',
        name: 'StudentProfilePage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/profile/StudentProfilePage.vue')
      },
    //    Student Endpoints End
    //    Course Endpoints Start

    //    Courses Endpoints End
    ],
  },
  {
    path: "/",
    name: "NoAuth",
    component: () =>
        import(/* webpackChunkName: "base" */ "../layouts/NoNavBarLayout.vue"),
    children: [
      // {
      //   path: "",
      //   redirect: "/login",
      // },
      //  College Endpoints Start
      {
        path: '/college-registration/:invitationId',
        name: 'CollegeRegistration',
        props: true,
        component: () => import(/* webpackChunkName: "college" */ '../views/college/CollegeRegistration.vue')
      },
      {
        path: '/college/payment',
        name: 'CollegePayment',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/payment/CollegePaymentPage.vue')
      },
      {
        path: '/college/payment-success',
        name: 'CollegePaymentSuccess',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/payment/PaymentSuccessPage.vue')
      },
      {
        path: '/college/payment-failed',
        name: 'CollegePaymentFailed',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/payment/PaymentFailedPage.vue')
      },
      //  College Endpoints End
      //  Student Endpoints Start
      {
        path: '/student-registration/:invitationId',
        name: 'StudentRegistration',
        props: true,
        component: () => import(/* webpackChunkName: "student" */ '../views/student/StudentRegistration.vue')
      },
      {
        path: '/student/payment',
        name: 'StudentPayment',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/payment/StudentPaymentPage.vue')
      },
      {
        path: '/student/payment-success',
        name: 'StudentPaymentSuccess',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/payment/StudentPaymentSuccessPage.vue')
      },
      {
        path: '/student/payment-failed',
        name: 'StudentPaymentFailed',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/payment/StudentPaymentFailedPage.vue')
      },
      //  Student Endpoints End
      //  PassOut Student Endpoints End

      {
        path: '/passout-student-registration/',
        name: 'PassOutStudentRegistration',
        component: () => import(/* webpackChunkName: "student" */ '../views/passout/PassOutStudentRegistration.vue')
      },
      //  PassOut Student Endpoints End
      {
        path: '/login',
        name: 'Login',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue')
      },
      {
        path: '/forgot-username',
        name: 'ForgotUsername',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/ForgotUsernamePage.vue')
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/ForgotPasswordPage.vue')
      },
    ],
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: "/",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to.meta.requiresAuth);
  console.log(store.state.isLoggedIn);
  if (to.meta.requiresAuth && !store.state.isLoggedIn) next({ name: "Login" });
  else if (to.meta.isLoginPage && store.state.isLoggedIn)
    next({ name: "Home" });
  else {
    let metaRole = to.meta.role;
    if(!metaRole) {
      next();
    } else {
      // @ts-ignore
      const role = store.state.activeUser["account_role"];
      let roleCheck = "";
      if (role === 1 || role === 2 || role === 3 || role === 4 || role === 5) {
        roleCheck = "admin";
      }
      if (role === 6 || role === 7) {
        roleCheck = "college";
      }
      if (role === 8) {
        roleCheck = "student"
      }
      // @ts-ignore
      if (roleCheck.indexOf(metaRole) === -1) {
        next({name: "Home"});
      } else {
        next();
      }
    }
  }
});
export default router
