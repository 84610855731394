import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  sendCollegeInvitations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/registration-invites/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  reSendCollegeInvitations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // const formData = new FormData();

      // Object.keys(data).forEach((key: string) => {
      //   formData.append(key, data[key]);
      // });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/registration/resend_college_invites/`, data);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getCollegesList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/list/`, { params: data });
        commit("setCollegeListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getCollegeDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/details/${data.collegeId}`);
        commit("setCollegeData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getAdminCoursesList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/lms/courses/`, { params: data });
        commit("setAdminCoursesListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  adminApproveRejectCourse({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        if(key != "course_id") {
          formData.append(key, data[key]);
        }
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/lms/course/update/${data.course_id}/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const adminStore: Module<any, any> = {
  state: () => ({
    college: {},
    colleges: [],
    coursesList: [],
    totalPages: 0,
    collegesCount: 0,
    limit: 20,
    page: 0,
  }),
  mutations: {
    resetData(state) {
      state.colleges = [];
      state.collegesCount = 0
      state.coursesPage = 0
      state.totalPages = 0;
      state.limit = 20;
      state.page = 0;
    },
    setCollegeData(state, data) {
      state.college = data;
    },
    setCollegeListData(state, data) {
        state.colleges = data.colleges_list;
        state.collegesCount = data.total_count
        state.totalPages = Math.ceil(data.total_count / data.limit);
        state.limit = data.limit;
        state.page = data.page;
    },
    setAdminCoursesListData(state, data) {
        state.coursesList = data.courses_list;
        // state.coursesCount = data.total_count
        state.totalPages = Math.ceil(data.total_count / data.limit);
        state.limit = data.limit;
        state.coursesPage = data.page;
    },
  },
  actions,
  getters: {
    adminCollegeList(state) {
        return state.colleges;
    },
    adminCollegeCount(state) {
        return state.collegesCount;
    },
    adminCoursesPage(state) {
        return state.coursesPage;
    },
    adminCoursesList(state) {
        return state.coursesList;
    },
    collegesTotalPages(state) {
      return state.totalPages;
    },
    collegesPage(state) {
      return state.page;
    },
    adminCollege(state) {
        return state.college;
    }
  },
};

export default adminStore;
