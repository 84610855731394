import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {
    async fetchTrainingsData({commit}) {
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get('/api/v1/skillofferings/specialisation_technologies/',)
                commit('setTrainingData', response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    async fetchPsychometricHtmlData({commit}, data) {
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get('/api/v1/mmm/start_test/', {params: data});
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    async fetchTrainingsTableData({commit}, data) {
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            commit('resetTrainingTableData');
            try {
                const response = await axios.get('/api/v1/skillofferings/skill_offerings/', {
                    params: data
                })
                commit('setTrainingTableData', response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    async enrollStudent({commit}, data) {
        return new Promise(async (resolve, reject) => {

            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post('/api/v1/skillofferings/skill_offering_enrollment/', formData)
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    async fetchMyEnrolledList({commit}) {
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            commit('resetTrainingTableData');
            try {
                const response = await axios.get('/api/v1/skillofferings/skill_offering_enrollment_list/')
                commit('setTrainingTableData', response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
};
const studentCoursesStore: Module<any, any> = {
    state: () => ({
        collegesTypesSkills: [],
        skillsTableData: [],
        mapIconsData: {
            "Software Programming": "fa-solid fa-laptop-code",
            "Data Analysis": "fa-solid fa-chart-line",
            "Soft Skills": "fa-solid fa-comments",
            "Web based and Mobile based": "fa-solid fa-mobile-screen",
            "Cloud": "fa-solid fa-cloud",
            "Data Visualization": "fa-solid fa-chart-pie",
            "Fin Tech": "fa-solid fa-money-bill-trend-up",
            "Administration": "fa-solid fa-screwdriver-wrench",
            "Blockchain": "fa-brands fa-hive",
            "Assessment": "fa-solid fa-arrows-to-eye",
            "Manufacturing Technology": "fa-solid fa-oil-well",
            "Construction Tech": "fa-solid fa-person-digging",
            "Placement Preparatory Skills": "fa-solid fa-hands-asl-interpreting",
            "Artificial Intelligence/ Big Data Analytics": "fa-solid fa-brain",
            "Cybersecurity": "fa-solid fa-shield-halved",
            "Cloud Computing": "fa-solid fa-cloud",
            "Internet of Things": "fa-solid fa-wifi",
            "Artificial Intelligence": "fa-solid fa-brain",
            "Web, Mobile Development & Marketing": "fa-brands fa-connectdevelop",
            "Continuous Learning": "fa-brands fa-leanpub",
            "Product Management": "fa-solid fa-diagram-project",
            "Effective Communication": "fa-solid fa-comments",
            "Software Tools & Programming Languages": "fa-solid fa-laptop-code",
            "Robotic Process Automation": "fa-solid fa-robot",
            "Big Data Analytics": "fa-solid fa-database",
            "Vernacular Architecture": "fa-solid fa-boxes-stacked",
            "Design and construction": "fa-solid fa-pen-ruler",
            "urban agendas and tools": "fa-solid fa-screwdriver-wrench",
            "contemporary architecture modernism": "fa-solid fa-folder-tree",
            "software for water supply and sewerage infrastructure design": "fa-solid fa-house-flood-water-circle-arrow-right",
            "comprehensive experience": "fa-solid fa-list",
            "Aerospace Engineering": "fa-solid fa-plane-departure",
            "Agricultural and Food Engineering": "fa-solid fa-building-wheat",
            "Biological Sciences & Bioengineering": "fa-solid fa-dna",
            "Chemical Engineering": "fa-solid fa-flask-vial",
            "Civil Engineering": "fa-solid fa-helmet-safety",
            "Mechanical Engineering": "fa-solid fa-gears",
            "Design Engineering": "fa-solid fa-pen-ruler",
            "Public health": "fa-solid fa-laptop-medical",
            "Medical": "fa-solid fa-laptop-medical",
            "Psychology": "fa-solid fa-shapes",
            "Indian literary theory": "fa-solid fa-book",
            "sociology": "fa-solid fa-users",
            "Development Studies": "fa-solid fa-chart-line",
            "Human-environment relationships": "fa-brands fa-envira",
            "Environmental legal framework": "fa-brands fa-envira",
            "Strategies of IP": "fa-solid fa-stairs",
            "Biotechnology for commercialisation": "fa-solid fa-biohazard",
            "Management Studies": "fa-solid fa-diagram-project",
            "Managerial Economics": "fa-solid fa-circle-dollar-to-slot",
            "Accountance": "fa-solid fa-file-invoice",
            "Marketing": "fa-solid fa-bullhorn",
            "Operations": "fa-solid fa-network-wired",
            "Quantum optics": "fa-solid fa-eye",
            "Materials Characterization": "fa-solid fa-recycle",
            "Electronic and Vibrational states of Solids": "fa-solid fa-bolt",
            "holomorphic functions": "fa-solid fa-superscript",
            "Calculus": "fa-solid fa-wave-square",
            "Computational Thermo Fluids": "fa-solid fa-water",
            "Manufacturing": "fa-solid fa-oil-well",
            "Simulation": "fa-solid fa-chart-column",
            "Nanotechnology": "fa-solid fa-microscope",
            "Equilibrium aspects of biophysics": "fa-solid fa-dna",
            "Computing": "fa-solid fa-keyboard",
            "Computational Biology": "fa-solid fa-dna",
            "Designing & Programming": "fa-solid fa-laptop-code",
            "Software": "fa-solid fa-laptop-code",
            "Industrial Energy Analysis": "fa-solid fa-industry",
            "Hardware-Quality Maintenance": "fa-solid fa-memory",
            "Welding Technology": "fa-solid fa-trowel",
            "Electrical vehical Maintenance": "fa-solid fa-charging-station",
            "Drone": "fa-solid fa-bezier-curve",
            "Emerging Technologies": "fa-solid fa-microchip",
            "Mechatronics Tech": "fa-solid fa-robot",
            "Manufacture Analysis": "fa-solid fa-screwdriver-wrench",
            "Management Farmework": "fa-solid fa-people-group",
            "Stimulation Tech": "fa-solid fa-brain",
            "Power train Tech": "fa-solid fa-train-tram",
            "Embedded": "fa-solid fa-microchip",
            "Programming language": "fa-solid fa-code",
            "Web Development": "fa-solid fa-laptop-code",
            "Creative Problem Solving & Critical Thinking": "fa-solid fa-brain",
            "Innovation and Design Thinking": "fa-solid fa-lightbulb",
            "Collaboration & Team Work": "fa-solid fa-people-group",
            "Project Management": "fa-solid fa-diagram-project",
            "Program Management": "fa-solid fa-diagram-project",
            "Influencing and Negotiation": "fa-solid fa-handshake-angle",
            "Digital Leadership": "fa-solid fa-book-open-reader",
            "Interview Mantra Effective Communication": "fa-solid fa-chalkboard-user",
            "Augmented Reality & Virtual Reality": "fa-solid fa-vr-cardboard",
            "3D Printing & Modeling": "fa-solid fa-cubes",
            "Electronics": "fa-solid fa-car-battery",
            "Health care": "fa-solid fa-heart-circle-check"
        },
    }),
    mutations: {
        setTrainingData(state, data) {
            state.collegesTypesSkills = data;
        },
        setTrainingTableData(state, data) {
            state.skillsTableData = data;
        },
        resetTrainingTableData(state) {
            state.skillsTableData = [];
        }
    },
    actions,
    getters: {
        getCollegeTypeSkills(state) {
            return state.collegesTypesSkills
        },
        mapIconsData(state) {
            return state.mapIconsData;
        },
        skillsTableData(state) {
            return state.skillsTableData;
        }
    },
};

export default studentCoursesStore;
