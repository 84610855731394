import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {
    getStudentInvitationDetails({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/student/invite/${data.invitationId}/`);

                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    performStudentRegister({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data.studentData).forEach((key: string) => {
                formData.append(key, data.studentData[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/invite/${data.invitationId}/`, formData);
                commit("SET_LOADING", false);
                commit("SET_LOGGED_IN", true);
                const json = response.data;
                commit("saveToken", json.access);
                commit("saveRefreshToken", json.refresh);
                // commit("saveUser", JSON.stringify(json));
                dispatch("fetchProfileDetails");
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    performPassOutStudentRegistration({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data.studentData).forEach((key: string) => {
                formData.append(key, data.studentData[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/registration/pass-out/`, formData);
                commit("SET_LOADING", false);
                // commit("SET_LOGGED_IN", true);
                // const json = response.data;
                // commit("saveToken", json.access);
                // commit("saveRefreshToken", json.refresh);
                // // commit("saveUser", JSON.stringify(json));
                // dispatch("fetchProfileDetails");
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    submitStudentPayment({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/registration/payment-confirmation/`, formData);

                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getStudentRegistrationDropdown({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/data/student-registration-dropdowns/`);
                commit('setCasteData', response.data.caste);
                commit('setGenderData', response.data.gender);
                commit('setDegreeData', response.data.degree);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
};
const studentStore: Module<any, any> = {
    state: () => ({
        casteData: {},
        genderData: {},
        degreeData: {},
    }),
    mutations: {
        setCasteData(state, data: any) {
            state.casteData = data;
        },
        setGenderData(state, data: any) {
            state.genderData = data;
        },
        setDegreeData(state, data: any) {
            state.degreeData = data;
        }
    },
    actions,
    getters: {
        casteData(state) {
            return state.casteData;
        },
        genderData(state) {
            return state.genderData;
        },
        degreeData(state) {
            return state.degreeData;
        }
    },
};

export default studentStore;
