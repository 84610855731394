<template>
  <TransitionRoot appear :show="isLoading" as="template">
    <div id="loading-overlay" class="fixed inset-0 z-50 loading-parent overflow-y-auto">
      <div class="min-h-screen px-4 text-center">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="backdrop-filter backdrop-blur-sm fixed inset-0" />
        </TransitionChild>

        <span class="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <div
            class="
              w-48
              inline-block
              my-8
              overflow-hidden
              align-middle
              transition-all
              transform
            "
          >
            <svg
              id="L5"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve"
            >
              <circle fill="#304443" stroke="none" cx="6" cy="50" r="6">
                <animateTransform
                  attributeName="transform"
                  dur="1s"
                  type="translate"
                  values="0 15 ; 0 -15; 0 15"
                  repeatCount="indefinite"
                  begin="0.1"
                ></animateTransform>
              </circle>
              <circle fill="#304443" stroke="none" cx="30" cy="50" r="6">
                <animateTransform
                  attributeName="transform"
                  dur="1s"
                  type="translate"
                  values="0 10 ; 0 -10; 0 10"
                  repeatCount="indefinite"
                  begin="0.2"
                ></animateTransform>
              </circle>
              <circle fill="#304443" stroke="none" cx="54" cy="50" r="6">
                <animateTransform
                  attributeName="transform"
                  dur="1s"
                  type="translate"
                  values="0 5 ; 0 -5; 0 5"
                  repeatCount="indefinite"
                  begin="0.3"
                ></animateTransform>
              </circle>
            </svg>
          </div>
        </TransitionChild>
      </div>
    </div>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild } from "@headlessui/vue";
import { mapGetters } from "vuex";

export default {
  name: "LLoading",
  components: {
    TransitionRoot,
    TransitionChild,
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>

<style>
.loading-blur :not(#loading-overlay):not(#loading-overlay *) {
  filter: blur(5px);
}
.loading-parent {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
